import { handleAdvertiserAdd } from '@/api/DDJ/v3/advertiser/index.js';
export default {
  data() {
    return {
      postData: {},
      options: {},
      loading: {
        btn: false,
      },
      rules: {
        advertiserName: [
          {
            required: true,
            message: '请输入广告主名称',
            trigger: 'blur',
          },
        ],
        advertiserCode: [
          {
            required: true,
            message: '请输入广告主编号',
            trigger: 'blur',
          },
        ],
        advertiserSource: [
          {
            required: true,
            message: '请输入源',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    // 保存
    add(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        handleAdvertiserAdd(this.postData).then((res) => {
          console.log(res);
          this.$message({
            message: '添加成功！',
            type: 'success',
          });
          this.$router.push({
            path: '/click/advertiser/v3',
          });
        });
      });
    },
  },
};
