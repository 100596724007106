<template>
  <div class="task-add">
    <!-- <div class="main-Title bgff"><h2>Add Advertiser</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover">
        <el-form ref="postData" :model="postData" :rules="rules" label-width="110px">
          <el-form-item label="广告主名称" prop="advertiserName"
            ><el-input v-model="postData.advertiserName" placeholder="请输入广告主名称"
          /></el-form-item>
          <el-form-item label="广告主编号" prop="advertiserCode"
            ><el-input v-model="postData.advertiserCode" placeholder="请输入广告主编号"
          /></el-form-item>
          <el-form-item label="源" prop="advertiserSource"
            ><el-input v-model="postData.advertiserSource" placeholder="请输入源"
          /></el-form-item>
          <el-form-item label="备注" prop="remarks"
            ><el-input v-model="postData.remarks" placeholder="请输入备注"
          /></el-form-item>
          <el-form-item align="center">
            <el-button :loading="loading.btn" size="small" type="primary" @click="add('postData')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
  import DDJAdvertiserAddCon from '../../../controllers/DDJ/v3/advertiser/add';
  export default {
    name: 'AdvertiserAdd',
    ...DDJAdvertiserAddCon,
  };
</script>

<style></style>
